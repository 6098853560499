import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const BrochureSupplementPage = () => {
  const pdfUrl = `/Brochure-Supplement.pdf?v=${new Date().getTime()}`
  return (
    <Layout>
      <SEO title="Brochure Supplement (ADV Part 2B)" />
      <Container>
        <h1 className="text-center font-size-base">Item 1: Cover Page</h1>
        <h2 className="text-center font-size-base">
          Ant Money Investment Program <br />
          Wrap Fee Program Brochure Supplement
        </h2>
        <h2 className="text-center text-underline">
          Walter Wemple Cruttenden III
        </h2>
        <p className="text-center">
          Ant Money Advisors, LLC ("AMA")
          <br />
          4600 Campus Drive
          <br />
          Newport Beach, CA 92660
          <br />
          www.AntMoneyAdvisors.com
          <br />
          <br />
          (949) 629-4210
          <br />
          <br />
          March 16, 2022
        </p>
        <p>
          This brochure supplement provides information about Walter Cruttenden
          that supplements the brochure for the Ant Money Investment Program
          that AMA sponsors and manages. You should have received a copy of that
          brochure. Please contact info@antmoneyadvisors.com if you did not
          receive the Ant Money Investment Program brochure or if you have any
          questions about the contents of this supplement.
        </p>
        <p>
          Additional information about Walter Cruttenden is available on the
          SEC's website at{" "}
          <a href="https://adviserinfo.sec.gov/">www.adviserinfo.sec.gov</a>.
        </p>
        <h1 className="text-center font-size-base">
          Item 2: Educational Background and Business Experience
        </h1>
        <p>
          Walter Cruttenden (b. 1950) is a financial markets innovator, an
          active founder investor in growth companies that serve a social need.
        </p>
        <p>
          Walter is a co-founder and will serve as Chief Investment Officer of
          AMA.
        </p>
        <p>
          Walter serves as Chairman of Acorns Grow, Inc., a micro investing
          company, which he co-founded. Acorns is an app that enables people to
          save and invest in incredibly small increments, with high frequency,
          requiring little or no conscious effort. Acorns owns Acorns Advisers,
          LLC, an investment adviser, and Acorns Securities, LLC, a carrying
          broker-dealer. Walter and his son, Jeffrey Cruttenden, developed the
          initial model portfolios for the Acorns Advisers Wrap Fee Program.
          Walter serves on Acorns Advisers, LLC's Investment Committee.
        </p>
        <p>
          Walter serves as Chairman of Blast Intergalactic Group, Inc. ("BIG"),
          a gamification company operating at the nexus of the Fintech and Game
          industries. BIG's app, Blast, features a new game dynamic that
          improves the financial outcome for both gamers and game companies
          alike. Walter co-founded Ant Money, Inc. ("AMI"). BIG and AMI each own
          half of AMA's parent company, Ant Farm, Inc.
        </p>
        <p>
          In his early career, Walter founded and served as CEO of two
          well-known investment banking and brokerage firms; Cruttenden Roth
          (now Roth Capital, active underwriters of emerging growth companies),
          and E*Offering, formerly the investment banking arm of E*Trade
          Securities (since merged). As CEO, Walter led both companies through
          rapid growth and significant liquidity events. Walter also co-founded
          SRS Laboratories (since acquired by DTS), after buying the acoustic
          technology from Hughes, and developing it into an independent company.
          Cruttenden also founded one of the largest growth stock conferences in
          the US, now the Roth Conference.
        </p>
        <p>Walter has no formal education after high school.</p>
        <h1 className="text-center font-size-base">
          Item 3: Disciplinary Information
        </h1>
        <p>
          Walter Cruttenden has not been the subject of or involved in any
          material disciplinary or enforcement event.
        </p>
        <h1 className="text-center font-size-base">
          Item 4: Other Business Activities
        </h1>
        <p>
          Walter Cruttenden is not currently registered, and has no application
          pending to register, as a broker-dealer, registered representative of
          a broker-dealer, futures commission merchant ("FCM"), commodity pool
          operator ("CPO"), commodity trading advisor ("CTA"), or an associated
          person of an FCM, CPO, or CTA. Walter is registered with the State of
          California as an investment adviser representative of Acorns Advisers,
          LLC. He is a founder and member of the Investment Committee of Acorns
          Advisers, LLC. He is Chairman of Acorns Grow, Inc., which owns and
          controls Acorns Advisers, LLC and its affiliated SEC-registered
          broker-dealer and Financial Industry Regulatory Authority member firm,
          Acorns Securities, LLC (Acorns Grow, Inc., Acorns Advisers, LLC, and
          Acorns Securities, LLC, referred to collectively as "Acorns").
        </p>
        <p>
          Walter's relationship with Acorns does not create a material conflict
          with the interests of AMA's clients. His work with Acorns does not
          impose demands on his time that materially impair his ability to
          fulfill his duties to AMA's clients with respect to selection of model
          portfolio funds and the list of individual stocks from which clients
          choose.
        </p>
        <p>
          Walter Cruttenden does not receive distribution or service fees from
          the sale of mutual funds or any other commissions, bonuses or other
          compensation based on the sale of securities or other investment
          products.
        </p>
        <p>
          Walter expends approximately 10 to 20 percent of his time working on
          the activities of each of the following AMA affiliates: Blast
          Intergalactic Group, Inc. ("BIG") and Ant Money, Inc. ("AMI").
        </p>
        <h1 className="text-center font-size-base">
          Item 5: Additional Compensation
        </h1>
        <p>
          Walter is a co-founder and part owner of AMA's affiliates, BIG and
          AMI. BIG earns revenue from the provision of educational content and
          technology for the Learn &amp; Earn mobile application and through
          publisher affiliate programs for the Blast mobile application, in
          which participants of either may invest through the Ant Money
          Investment Program. AMI earns revenue from advertising in the ATM.com
          app, which generates funds that users may invest through the Ant Money
          Investment Program.
        </p>
        <h1 className="text-center font-size-base">Item 6: Supervision</h1>
        <p>
          AMA's Chief Compliance Officer supervises Walter Cruttenden's
          activities with respect to Walter's activities as Chief Investment
          Officer, his compliance with the Investment Advisers Act of 1940, as
          amended, and his fiduciary duties to AMA's clients. Among other
          things, the Chief Compliance Officer reviews the following no less
          frequently than quarterly and at other times the Chief Compliance
          Officer deems advisable in his discretion: (i) Walter's selection of
          funds for AMA's model portfolio; (ii) Walter's selection of stocks for
          the Eligible Stock list from which clients may choose; (iii) Walter's
          and AMA's arrangements with BIG and AMI, if any, regarding
          compensation generated by activities of AMA clients; and (iv) Walter's
          personal, family, and trust investment transactions to identify
          potential conflicts of between Walter's interests and those of AMA
          clients. AMA's Chief Compliance Officer is currently William Topaz,
          who is reachable through AMA at (949) 629-4210 and{" "}
          <a href="mailto:info@antmoneyadvisors.com">
            info@antmoneyadvisors.com
          </a>
          .
        </p>
        <hr />
        <p>
          <a href={pdfUrl} target="_blank" rel="noreferrer">
            Click here
          </a>{" "}
          to download the Brochure Supplement document.
        </p>
      </Container>
    </Layout>
  )
}

export default BrochureSupplementPage
